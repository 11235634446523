import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {getHelper} from "../../general/apiHelper";
import {BASE_URL} from "../../general/constants";

export const fetchProperties = createAsyncThunk(
	'properties/fetchProperties',
	async ({accommodation}) => {
		const response = await getHelper(`${BASE_URL}/properties`,
			{Authorization:`lid ${accommodation}`});  // adjust the URL accordingly
		return response;
	}
);

const initialState = {
	status: 'idle',
	error: null,
	tickets: {},
	properties: {},
	googleTagManagerId: null,
};

const propertiesSlice = createSlice({
	name: 'properties',
	initialState,
	reducers: {
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchProperties.pending, (state) => {
				state.status = 'loading';
			})
			.addCase(fetchProperties.fulfilled, (state, action) => {
				const general = action.payload.filter(o => o.category === 'general')[0]
				const tickets = action.payload.filter(o => o.category === 'tickets')[0]
				state.status = 'succeeded';
				state.properties = general;
				state.tickets = tickets;
				state.googleTagManagerId = general?.values?.googleTagManagerId || "G-MJFY5YEE5C"
			})
			.addCase(fetchProperties.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.error.message;
			});
	},
});

export default propertiesSlice.reducer;
